// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/providers/location/provider.ts"
);
import.meta.hot.lastModified = "1740495432000";
}
// REMIX HMR END

import { QueryOptions, sdk } from '~storefront/graphqlWrapper'

import {
  RsvLocationListOptions,
  Rsv_LocationQuery,
  Rsv_LocationsQuery,
  Rsv_TimezonesQuery,
  Rsv_LocationFormQuery,
} from '~storefront/generated/graphql'

export const listTimezones = (): Promise<Rsv_TimezonesQuery> => {
  return sdk.rsv_timezones()
}

export const get = (
  id: string,
  options?: QueryOptions,
): Promise<Rsv_LocationQuery> => {
  return sdk.rsv_location({ id }, options)
}

export const getForm = (
  id: string,
  options?: QueryOptions,
): Promise<Rsv_LocationFormQuery> => {
  return sdk.rsv_locationForm({ id }, options)
}

export const list = (
  options?: RsvLocationListOptions,
  queryOptions?: QueryOptions,
): Promise<Rsv_LocationsQuery> => {
  return sdk.rsv_locations({ options }, queryOptions)
}
